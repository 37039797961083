import React from "react";
import "./styles.scss";



const ContactUsPopup = () => {

  const handleContactUsDialog = () => {
    const contactUsDialog = document.querySelector("#contact-us-dialog-box");
    
    if(contactUsDialog) {
      contactUsDialog.showModal();
    }
  };
    
  const handleDialogClose = () => {
    const contactUsDialog = document.querySelector("#contact-us-dialog-box");

    if(contactUsDialog) {
      contactUsDialog.close();
    }
  };

  return (
    <>
      <button className="contact-us-popup-btn" onClick={handleContactUsDialog} >
        <img
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ousnvs.png"
          alt="contact-us-popup-btn"
          loading="lazy"
        />
      </button>

      <dialog id="contact-us-dialog-box">
        <div className="outer-box">
          <div className="dialog-header">
            <div className="header-text">Got a Question?</div>
          </div>
          <button className="dialog-toggle-btn" onClick={handleDialogClose}> ✕ </button>
          <div className="inner-box">
            <div className="phone-dialog-img">
              <img
                src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y16fv8m1ouvbq8.png"
                alt="dialogImg"
                loading="lazy"
              />
            </div>
            <div className="phone-info-box">
              <p className="text">
                                For any query, you can call us at:
              </p>
              <p className="phone-no-text">
                <a href="tel:+917827135490">
                                    +91 7827135490
                </a>
              </p>
            </div>
            <hr className="hr-style"/>
            <div className="email-info-box">
              <p className="text">
                                Or email us at:
              </p>
              <p className="email-id-text">
                <a href="mailto:contact@kraftshala.com">contact@kraftshala.com</a>
              </p>
            </div>
          </div>
        </div>
      </dialog>

    </>
  );
};

export default ContactUsPopup;